body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  position: absolute;
  top: 8px;
  left: 10px;  
  height: 50px;
  z-index: 1;  
}

@media only screen and (max-width: 600px) {
  .logo {
    top: 50px;
  }
}